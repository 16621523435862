(() => {
    // src/components/WebChat.js
    var WebChat = class extends HTMLElement {
      connectedCallback() {
        let configs = JSON.parse(this.getAttribute("configs"));
        let domain = window.location.href;
        let configDomain = configs.websiteDomainName;
        let updatedSubDomainArray = [];
        let combinedDomainAndSubDomain = [];
        const removePrefix = (domain2) => {
          const prefixesRegex = /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www\.)/;
          const match = domain2.match(prefixesRegex);
          if (match) {
            const prefix = match[0];
            const remainingDomain = domain2.replace(prefix, "");
            return { remainingDomain };
          }
          return { remainingDomain: domain2 };
        };
        const removeSlash = (domain2) => {
          const cleanedDomain = domain2.replace(/\/.*$/, "");
          return cleanedDomain;
        };
        if (removePrefix(domain)) {
          const { remainingDomain } = removePrefix(domain);
          domain = remainingDomain;
          if (removeSlash(remainingDomain)) {
            domain = removeSlash(domain);
          }
        }
        if (removePrefix(configDomain)) {
          const { remainingDomain } = removePrefix(configDomain);
          configDomain = remainingDomain;
          if (removeSlash(remainingDomain)) {
            configDomain = removeSlash(configDomain);
          }
        }
        if (configs?.subDomains?.length > 0) {
          configs.subDomains.forEach((sub) => {
            if (removePrefix(sub)) {
              let { remainingDomain } = removePrefix(sub);
              if (removeSlash(remainingDomain)) {
                remainingDomain = removeSlash(remainingDomain);
              }
              updatedSubDomainArray.push(remainingDomain);
            } else {
              updatedSubDomainArray.push(sub);
            }
          });
        }
        if (configs?.subDomains?.length == 0) {
          combinedDomainAndSubDomain.push(configDomain);
        } else {
          combinedDomainAndSubDomain.push(configDomain);
          updatedSubDomainArray.forEach((sub) => {
            combinedDomainAndSubDomain.push(sub);
          });
        }
        let isDomainMatched = combinedDomainAndSubDomain.includes(domain);
        if (!isDomainMatched) {
          // console.log("domains does not support");
          return;
        }
        this.innerHTML = `
              <style>
              .whatsapp-btn {
                position: fixed;
                right: 20px;
                bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                padding: 8px 10px; 
                border-radius: 24px;
                cursor: pointer;
                background-color: #fff; 
                        z-index: 999999 !important;
              }
              
              .whatsapp-btn .outer-btn {
                cursor: pointer;
                border: none;
                display: flex;
                align-items: center;
                background-color: #fff; 
                transition: transform 0.2s ease-in-out;
              }
              .whatsapp-btn .outer-btn span {
                margin-left: 0.25rem;
                font-weight: bold;
  
              }
              
              .whatsapp-btn .outer-btn:hover {
                transform: scale(1.08);
              }
              
              .whatsapp-modal {
                position: fixed;
                right: 20px;
                bottom: 80px;
                display: none;
                border-radius: 10px;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                padding: 24px;
                background-color: #fff;
                        z-index: 999999 !important;
                max-width: 280px;
                width: 100%;
              }
              
              .whatsapp-modal.active {
                display: block; 
              }
              
              .whatsapp-modal h2 {
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 16px;
                text-align: center;
                color: #186256;
              }
              
              .whatsapp-modal textarea {
                width: -webkit-fill-available !important;
                background-color: #f1f1f1;
                padding: 8px; 
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 13px;
                resize: vertical; 
                outline: none;
              }
              
              .whatsapp-modal textarea:focus {
                ring: 1px solid #3b82ef; 
              }
              .send-btn {
                background-color: #186256;
                width: 100%;
                color: #fff;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                border-radius: 0.375rem;
                cursor: pointer;
                border:none;
                font-size:12px;
            }
            
            .send-btn:hover {
                background-color: #177969;
            }
  
              .modal-margin {
                margin-bottom: 1rem;
              }
              .copyright {
                display: flex;
                align-items: baseline;
                justify-content: center;
                width: 100%;
                margin-top: 0.5rem; 
                font-size: 12px;
                color: #808080; 
            }
            .copyright a {
                margin-left: 0.5rem; 
                font-size: 16px;
                font-weight: bold;
                color: #186256;
                text-decoration: none;
               
            }
  
              </style>
  
               <div id="whatsapp-btn" class="whatsapp-btn " >
               <button class="outer-btn ">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="30px" fill-rule="evenodd" clip-rule="evenodd"><path fill="#fff" d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"></path><path fill="#fff"
                d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"></path><path fill="#cfd8dc" d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032
                ,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"></path><path fill="#40c351" d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"></path><path fill="#fff" fill-rule="evenodd" d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z" clip-rule="evenodd"></path></svg>
                <span >Chat</span>
                </button ></div>
              
              <div id="whatsapp-modal" class="whatsapp-modal rounded-xl   fixed right-[20px] bottom-[80px]  shadow-3xl p-6   bg-white z-[1000] max-w-[380px] w-full">
                  <h2 >${configs?.siteOrBusinessName || "Send message"} </h2>
                  <div class="modal-margin">
                      
                      <textarea id="message"
                          rows="4" placeholder="Enter your message..." >${configs?.defaultMessage}</textarea>
                  </div>
                  <button id="send-btn"
                      class="send-btn ">Chat With Us</button>
                      <div class="copyright">
                      <div>Powered by</div>
                      <div> <a  href="https://zbot.chat/">zbot.chat</a> </div>
                      </div>
              </div>
          `;
        const whatsappBtn = this.querySelector("#whatsapp-btn");
        const whatsappModal = this.querySelector("#whatsapp-modal");
        const sendBtn = this.querySelector("#send-btn");
        const messageInput = this.querySelector("#message");
        whatsappBtn.addEventListener("click", () => {
          whatsappModal.classList.toggle("active");
        });
        document.addEventListener("click", (event) => {
          if (!whatsappBtn.contains(event.target) && !whatsappModal.contains(event.target) && whatsappModal.classList.contains("active")) {
            whatsappModal.classList.remove("active");
          }
        });
        messageInput.addEventListener("change", (e) => {
          configs.defaultMessage = e.target.value;
        });
        sendBtn.addEventListener("click", () => {
          const message = encodeURIComponent(configs.defaultMessage);
          const whatsappNumber = configs?.whatsappNumber;
          const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
          window.open(whatsappUrl, "_blank");
        });
      }
    };
    customElements.define("web-chat", WebChat);
  })();
  
          function addWebComponentWithConfig(config) {
            const webComponent = document.createElement('web-chat');
            webComponent.setAttribute('configs', JSON.stringify(config));
            return webComponent;
          }
         export function initializeWebchat() {
           if (typeof window !== 'undefined') {
          const config = {"siteOrBusinessName":"Zversal","whatsappNumber":"9872955597","defaultMessage":"Hi","websiteDomainName":"https://www.zversal.com/"};
          const myWebComponent = addWebComponentWithConfig(config);
          const targetElement = document.getElementById('web-chat');
          if (targetElement) {
            targetElement.appendChild(myWebComponent);
          } else {
            console.error('Target element not found!');
          }}
        }
    
          
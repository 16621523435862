// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './src/styles/fonts.css';

import { initializeWebchat } from './src/components/Webchat.js'

export const onRouteUpdate = () => {
    if (typeof window !== 'undefined') {
        initializeWebchat();

    }
};